<template>
  <div id="About" class="about top-container">
    <div class="bg-light landing-box">
      <div class="container-fluid curve-block-container w-75">
        <div class="row mt-4">
          <div class="col-lg-6 col-md-6 col-sm-12 text-left">
            <h1>Welcome to Block Party</h1>
            <p>
              We make local policy information accessible and byte-sized, with a
              weekly email of your community board’s most recent meeting
              synopsis and transcript data.
            </p>
          </div>
          <div class="col-lg-6 col-md-6 col-sm-12 landing-image">
            <img
              :src="landingImage"
              alt="landing-image"
              class="landing-image"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid curve-container">
      <svg
        class="curve-container__curve curve-three"
        viewBox="10 -20 1870 210"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
      >
        <filter
          id="up-shadow"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feComponentTransfer in="SourceAlpha">
            <feFuncR type="discrete" tableValues="0"></feFuncR>
            <feFuncG type="discrete" tableValues="0"></feFuncG>
            <feFuncB type="discrete" tableValues="0"></feFuncB>
            <feFuncA type="linear" slope="0.3"></feFuncA>
          </feComponentTransfer>
          <feGaussianBlur stdDeviation="15"></feGaussianBlur>
          <feOffset dx="0" dy="0" result="shadow"></feOffset>
          <feComposite
            in="SourceGraphic"
            in2="shadow"
            operator="over"
          ></feComposite>
        </filter>
        <filter id="down-shadow">
          <feFlood flood-color="rgba(0,0,0,0.5)"></feFlood>
          <feComposite in2="SourceAlpha" operator="out"></feComposite>
          <feGaussianBlur stdDeviation="15"></feGaussianBlur>
          <feOffset dx="0" dy="0" result="shadow"></feOffset>
          <feComposite operator="atop" in2="SourceGraphic"></feComposite>
        </filter>
        <path
          class="fill-"
          fill="#eeeeee"
          d="M977.9,76.2 C475.2,-17.4 0.2,132.5 0.2,132.5 L0.2,275.5 L1891.3,275.5 L1891.3,0.7 C1891.3,0.7 1480.6,169.8 977.9,76.2 Z"
          id="Path"
        ></path>
      </svg>
    </div>
    <div class="container-fluid w-75 bg-white">
      <div class="row mt-4">
        <div
          class="col-lg-3 col-md-3 col-sm-6 mx-auto text-center border-bottom-highlight"
        >
          <h2>Our Goals</h2>
        </div>
      </div>
      <div class="row spacing-margin-lg"></div>
      <div class="row mt-4">
        <div class="col-lg-4 col-md-4 col-sm-12 text-left">
          <h3>Inform</h3>
          <p class="text-justify">
            Democracy is not a spectator sport - those who engage can shape our
            city and day-to-day life; we are here to help you stay in the know.
          </p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <img :src="cloudImage" alt="cloud" class="body-image" />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 text-left">
          <h3>Accessible</h3>
          <p class="text-justify">
            We believe local policy information should be easily accessible and
            available to all communities.
          </p>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-4 col-md-4 col-sm-12 text-left">
          <h3>AI</h3>
          <p class="text-justify">
            We use Artificial Intelligence (AI) and Natural Language Processing
            (NLP) to make the meeting conversation more digestible and
            byte-sized.
          </p>
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12">
          <img :src="botImage" alt="bot-image" class="body-image" />
        </div>
        <div class="col-lg-4 col-md-4 col-sm-12 text-left">
          <h3>Research</h3>
          <p class="text-justify">
            Our transcript data is created from community board meetings -
            therefore, it should belong to the community of Open Data.
          </p>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-6 col-md-6 col-sm-12 mx-auto text-center">
          <b-button size="lg" variant="primary" @click="goToArchive"
            >Go to Community Board Archive</b-button
          >
        </div>
      </div>

      <div class="row spacing-margin-lg"></div>

      <div class="row mt-4">
        <div
          class="col-lg-3 col-md-3 col-sm-6 mx-auto text-center border-bottom-highlight"
        >
          <h2>Contact</h2>
        </div>
      </div>

      <div class="row mt-4">
        <div class="col-lg-6 col-md-6 col-sm-6 mx-auto">
          <p>
            We'd love to hear from you - reach out on Twitter
            <a href="https://twitter.com/my_block_party" target="_blank"
              >@my_block_party.</a
            >
          </p>
          <a href="https://twitter.com/my_block_party" target="_blank">
            <div class="link twitter">
              <svg
                style="overflow: style; height: 3rem; width: auto"
                aria-hidden="true"
                focusable="false"
                data-prefix="fab"
                data-icon="twitter"
                class="svg-inline--fa fa-twitter fa-w-16"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 512 512"
              >
                <path
                  fill="currentColor"
                  d="M459.37 151.716c.325 4.548.325 9.097.325 13.645 0 138.72-105.583 298.558-298.558 298.558-59.452 0-114.68-17.219-161.137-47.106 8.447.974 16.568 1.299 25.34 1.299 49.055 0 94.213-16.568 130.274-44.832-46.132-.975-84.792-31.188-98.112-72.772 6.498.974 12.995 1.624 19.818 1.624 9.421 0 18.843-1.3 27.614-3.573-48.081-9.747-84.143-51.98-84.143-102.985v-1.299c13.969 7.797 30.214 12.67 47.431 13.319-28.264-18.843-46.781-51.005-46.781-87.391 0-19.492 5.197-37.36 14.294-52.954 51.655 63.675 129.3 105.258 216.365 109.807-1.624-7.797-2.599-15.918-2.599-24.04 0-57.828 46.782-104.934 104.934-104.934 30.213 0 57.502 12.67 76.67 33.137 23.715-4.548 46.456-13.32 66.599-25.34-7.798 24.366-24.366 44.833-46.132 57.827 21.117-2.273 41.584-8.122 60.426-16.243-14.292 20.791-32.161 39.308-52.628 54.253z"
                ></path>
              </svg>
            </div>
          </a>
        </div>
      </div>

      <div class="row spacing-margin-lg"></div>
      <div class="row spacing-margin-lg"></div>
      <div class="row">
        <div class="col mx-auto">
          <b-button
            id="terms-of-use"
            variant="outline-info"
            size="sm"
            title="terms of use"
            aria-label="terms of use"
            v-b-toggle.disclaimer-collapse
            >Terms of Use</b-button
          >
        </div>
      </div>
      <hr />
      <div class="row mt-4">
        <div class="col-lg-12 col-md-12 col-sm-12">
          <b-collapse id="disclaimer-collapse">
            <termsOfUse></termsOfUse>
          </b-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import termsOfUse from '@/components/TERMS_OF_USE.vue'

export default {
  data() {
    return {
      logo: require('@/assets/img/logo.png'),
      landingImage: require('@/assets/img/building_hats_2.png'),
      cloudImage: require('@/assets/img/cloud_2.png'),
      botImage: require('@/assets/img/bot.png'),
      toggleDisclaimer: false
    }
  },
  components: {
    termsOfUse
  },
  methods: {
    goToArchive() {
      this.$router.push('/Archive')
    }
  }
}
</script>
<style lang="scss" scoped>
$twitter-blue: #6cadde;

.link {
  transition: all 0.1s ease-in;
  cursor: pointer;

  &.twitter {
    color: $twitter-blue;

    &:hover {
      @include lightenColor($twitter-blue, 10%);
    }
  }
}
#About {
  .landing-image {
    height: 250px;
    width: auto;
  }
  .body-image {
    height: 200px;
    width: auto;
  }
}
.border-bottom-highlight {
  border-bottom: 5px solid $active-color;
  border-radius: 2px;
}
.curve-block-container {
  padding-top: 18px;
  padding-bottom: 50px;
}

.curve-container {
  margin-bottom: -1px;
  // margin-top: -80px;
  margin-top: -10%;
}

.curve-container__curve {
  transform: scaleX(-1);
  padding-bottom: 0;
  position: relative;
  display: block;
  margin-bottom: -1px;

  path {
    fill: white;
  }
}
.container-fluid {
  padding-left: 0px;
  padding-right: 0px;
  position: relative;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .landing-box {
    height: 450px !important;
  }
}
// Large devices (desktops, 992px and up)
@media (min-width: 992px) and (max-width: 1199.98px) {
  .landing-box {
    height: 450px !important;
  }
}

// Extra large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .landing-box {
    height: 450px !important;
  }
}
</style>
